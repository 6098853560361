import { withNavigationWatcher } from './contexts/NavigationContext';
import ApprovalPage from './pages/ApprovalPage';
import QuotationRequestPage from './pages/QuotationRequestPage';

const routes = [
  {
    path: '/quotation-request/:linkId',
    component: QuotationRequestPage
  },
  {
    path: '/quotation-request/',
    component: QuotationRequestPage
  },
  {
    path: '/approval',
    component: ApprovalPage
  },
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
