import { Layout } from 'antd';
import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Steps, Space } from 'antd';
import { QuotationRequestModel } from '../models/QuotationRequestModel';
import { UserOutlined, SolutionOutlined, SmileOutlined } from '@ant-design/icons';
const { Header, Content, Footer } = Layout;
const { Step } = Steps;

export default () => {
  const [mockQuotationRequest, setMockQuotationRequest] = useState({});

  useEffect(() => {
    var newQuotationRequest = new QuotationRequestModel();
    newQuotationRequest.id = "123"
    newQuotationRequest.nameOfRequester = "Börje Enqvist";
    newQuotationRequest.vehicleName = "Volvo Amazon";
    setMockQuotationRequest(newQuotationRequest);
  }, []);

  return (
    <div className="App">
        
        <div style={{ width: '80%', margin: 'auto' }}>

          <Steps>
            <Step status="done" title="Godkänn offert" icon={<UserOutlined />} />
            <Step status="process" title="Attest" icon={<SolutionOutlined />} />
            <Step status="wait" title="Klar!" icon={<SmileOutlined />} />
          </Steps>
        </div>

        <Space direction="vertical" size="large" />

        <Layout>
    <Card title="Attestera" bordered={true}>
      <Form
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 14,
                  }}
                  layout="horizontal"
                  initialValues={{
                    size: 'large',
                  }}
                >
                  <Form.Item label="Button">
                    <Button>Acceptera</Button>
                  </Form.Item>
                </Form>
    </Card>
    </Layout>
    </div>
  )
};
