import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: {
      "Complete order": "Complete order",
      "No quote selected": "No quote selected",
      "No quote selected - description": "The quote you looked for does not seem to exist.",
      "Time to change vehicle": "It is time to change your vehicle. Read the offer through and complete it with your wishes. When you are satisfied, approve the quote.",
      "Manual": "Manual",
      "Automatic": "Automatic",
      "Spikes": "Spikes",
      "NoSpikes": "No Spikes",
      "NoWinterTires": "No Winter Tires",
      "Approval failed": "Approval failed",
      "Vehicle to replace": "Vehicle to replace",
      "Saved": "Saved!",
      "Comment has been sent": "Comment has been sent to the vehicle administrator",
      "Approved": "Approved!",
      "Quote has been approved": "The purchase quote has been approved and sent to the vehicle administrator",
      "Declined": "Declined!",
      "Quote has been declined": "The purchase quote has been declined and sent to the vehicle administrator",
    }
  },
  sv: {
    translation: {
      "Complete order": "Komplettera beställning",
      "No quote selected": "Ingen offert vald",
      "No quote selected - description": "Det verkar som att offerten du söker inte finns.",
      "Time to change vehicle": "Det är dags att byta ditt fordon. Var vänlig läs igenom, komplettera med önskemål och bekräfta att du vill gå vidare och få avtalsförslag.",
      "Manual": "Manuell",
      "Automatic": "Automat",
      "Spikes": "Dubbdäck",
      "NoSpikes": "Dubbfria",
      "NoWinterTires": "Inga vinterdäck",
      "Approval failed": "Godkännande misslyckades",
      "Vehicle to replace": "Fordon som ska bytas ut",
      "Saved": "Sparad!",
      "Comment has been sent": "Kommentaren har skickats till fordonsansvarig",
      "Approved": "Godkänd!",
      "Quote has been approved": "Offerten har godkänts och skickats till fordonsansvarig",
      "Declined": "Avböjd!",
      "Quote has been declined": "Offerten har avböjts och skickats till fordonsansvarig",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "sv", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
