import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Comment, Avatar, List, notification } from 'antd';
import moment from 'moment';
import 'moment/locale/sv'
import { UserOutlined } from '@ant-design/icons';
import { VehiclePurchaseService } from '../services/VehiclePurchaseService';
import { useTranslation } from 'react-i18next';
const { TextArea } = Input;

export default (props) => {
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [textBoxValue, setTextBoxValue] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const { t, i18n } = useTranslation();
  moment.locale("sv");

  var handleSubmit = () => {
    if (!textBoxValue) {
      return;
    }

    VehiclePurchaseService.comment(props.linkId, textBoxValue);

    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
      setTextBoxValue("");
      showNotification();

      const newState = [...comments, {
        author: buyerEmail,
        avatar: <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />,
        content: <p>{textBoxValue}</p>,
        datetime: moment().fromNow(),
      }];

      setComments(newState);
    }, 1000);
  };

  var handleChange = (e) => {
    setTextBoxValue(e.target.value);
  };

  useEffect(() => {
    setComments(props.loadedComments.map(x => ({
      author: x.commetType == "Buyer" ? props.author : x.senderName,
      avatar: <Avatar icon={<UserOutlined />} style={ x.commetType == "Buyer" ? { backgroundColor: '#87d068' } : {  } } />,
      content: <p>{x.message}</p>,
      datetime: moment(x.created).fromNow(),
    })));

    setBuyerEmail(props.author);
  }, []);

  var showNotification = () => {
    var saved = t('Saved');
    var savedMessage = t('Comment has been sent');
    notification.success({
      message: saved,
      description: savedMessage,
      placement: 'topRight'
    })
  }

  return (
      <div>
        {
          comments.length > 0 && (
            <List
              dataSource={comments}
              itemLayout="horizontal"
              renderItem={props => <Comment {...props} />}
            />
          )
        }

        { !props.disableComments && (
          <Comment
            avatar={
              <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            }
            content={
              <div>
                <Form.Item>
                  <TextArea rows={4} onChange={handleChange} value={textBoxValue} />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" loading={submitting} onClick={handleSubmit} type="primary">
                    Lägg till kommentar
                  </Button>
                </Form.Item>
              </div>
            }
          />)
        }
      </div>
  )
};
