class QuotationRequestModel {
    id;

    requesteeEmail; // test@example.com

    vehicleRegistrationNumber; // ABC123
    periodStart; // 2001-01-01
    periodEnd; // 2003-02-01

    supplier; // Bilia

    vehicleManufacturer; // Volvo
    vehicleModel; // V60 Kombi
    vehicleColor;

    comment; // Kommentar från admin?

    replyComment; // Kommentar tillbaka till admin
}

export { QuotationRequestModel }


/*
{
  "id": "39b31948-5df4-4dcb-9f23-c8bf1b734eff",
  "status": "Rejected",
  "buyerInformation": {
    "email": "kalle@kula.se",
    "organizationUnitId": "b28fe54e-de94-4fdb-bb34-9975abc8ae88"
  },
  "sellerContact": {
    "contactTenantUserId": "b28fe54e-de94-4fdb-bb34-9975abc8ae88",
    "organizationUnitId": "b28fe54e-de94-4fdb-bb34-9975abc8ae88"
  },
  "estimatedDeliveryDate": "2021-06-10T18:02:00.561+00:00",
  "agreementLengthInMonths": 10,
  "make": "Lada",
  "model": "Kass",
  "color": "Rost röd",
  "towbar": false,
  "transmission": "Manual",
  "winterTireOption": "Spikes",
  "monthlyFee": 999990,
  "createdTime": "2021-06-10T18:05:09.3651882+00:00",
  "comments": [
    {
      "id": "a9bdc86b-03de-4d54-80c0-0ccf35b47b29",
      "commetType": "Buyer",
      "created": "2021-06-10T18:54:53.4909894+00:00",
      "message": "Skicka kommentar med linkId"
    }
  ],
  "hasUnreadSellerComments": false,
  "hasUnreadBuyerComments": true
}
*/