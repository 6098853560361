import { Divider, Layout } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Row, Col, PageHeader, Typography, Descriptions, Result, Spin, Button, Space, Input, Steps, notification } from 'antd';
import {
  InfoCircleTwoTone, SafetyCertificateOutlined, CheckOutlined, CloseOutlined, RetweetOutlined, SwapOutlined, 
  UserOutlined, SolutionOutlined, RightOutlined, LoadingOutlined, SmileOutlined, MessageOutlined, LikeOutlined
} from '@ant-design/icons';
import CommentComponent from '../components/CommentComponent';
import NumberFormat from 'react-number-format';
import { VehiclePurchaseService } from '../services/VehiclePurchaseService';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/sv'
import VehicleCard from '../components/VehicleCard';

const { Header, Content } = Layout;
const { Step } = Steps;
const { Title } = Typography;



export default () => {
  const [quotationRequest, setQuotationRequest] = useState();
  const [vehicleToReplace, setVehicleToReplace] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [verifiedWithCode, setVerifiedWithCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isLoadingQuote, setIsLoadingQuote] = useState(false);
  const [failedToLoadQuote, setFailedToLoadQuote] = useState(false);
  const { linkId } = useParams();
  const { t, i18n } = useTranslation();

  var showNotification = (title, message) => {
    notification.success({
      message: title,
      description: message,
      placement: 'topRight'
    })
  }

  var onVerificationCodeChange = (e) => {
    var v = e.target.value;

    setVerificationCode(v);
    if (v.length > 4) {
      setVerifiedWithCode(true);
    } else {
      setVerifiedWithCode(false);
    }
  }

  var onApprove = () => {
    setIsSubmitting(true);
    VehiclePurchaseService.approve(linkId, verificationCode).then(x => {
      getQuote(linkId);
      var title = t('Approved');
      var message = t('Quote has been approved');
      showNotification(title, message);
    }, (error) => {
      notification['error']({
        message: t('Approval failed'),
        description:
          'Det gick inte att godkänna förslaget. Kontrollera att verifikationskoden är korrekt och försök igen.',
      });
    });

    setTimeout(() => {
      setIsSubmitting(false);
    }, 4000);
  }

  var onDecline = () => {
    setIsDeclining(true);
    VehiclePurchaseService.reject(linkId, verificationCode).then(x => {
      getQuote(linkId);
      var title = t('Declined');
      var message = t('Quote has been declined');
      showNotification(title, message);
    }, (error) => {
      notification['error']({
        message: 'Avböjandet misslyckades',
        description:
          'Det gick inte att avböja förslaget. Kontrollera att verifikationskoden är korrekt och försök igen.',
      });
    });

    setTimeout(() => {
      setIsDeclining(false);
    }, 4000);
  }

  var isResponseDisabled = () => {
    return (isDeclining || isSubmitting) || !verifiedWithCode;
  }

  useEffect(() => {
    setIsLoadingQuote(true);
    getQuote(linkId);
    getVehicleToReplace(linkId);
  }, []);

  var getQuote = (linkId) => {
    VehiclePurchaseService.getQuoteByLinkId(linkId).then(x => {
      setQuotationRequest(x)
      setIsLoadingQuote(false);
    }, (error) => {
      setIsLoadingQuote(false);
      setFailedToLoadQuote(true);
    });
  }

  var getVehicleToReplace = (linkId) => {
    VehiclePurchaseService.getVehicleByLinkId(linkId).then(x => {
      setVehicleToReplace(x)
    }, (error) => {
      console.log(error);
    });
  }

  var vehicleRegistrationNumber = () => {
    if (vehicleToReplace != null) {
      return vehicleToReplace.registrationNumber;
    }
    return "";
  }
  var vehicleMake = () => {
    if (vehicleToReplace != null) {
      return vehicleToReplace.make + " " + vehicleToReplace.model;
    }
    return "";
  }

  if (isLoadingQuote) {
    return (
      <Content className="site-layout" style={{ padding: '0 50px', marginTop: 180 }}>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
          <div className="loader-quote">
            <Spin />
          </div>
        </div>
      </Content>
    );
  }

  if (failedToLoadQuote || quotationRequest == null || vehicleToReplace == null) {
    return (
      <Content className="site-layout" style={{ padding: '0 50px', marginTop: 180 }}>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>

          <Result
            status="404"
            title={t('No quote selected')}
            subTitle={t('No quote selected - description')}
          />
        </div>
      </Content>
    )
  }

  return (
    <Content className="site-layout" style={{ padding: '0 50px', marginTop: 180 }}>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <PageHeader
              className="site-page-header"
              title={t('Complete order')}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: 20 }}>
          <Col span={2}>
            <InfoCircleTwoTone style={{ fontSize: 60 }} />
          </Col>
          <Col span={12}>
            <Row>
              <Col>{t('Time to change vehicle')}</Col>
            </Row>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={4}></Col>
          <Col span={16}>
            <Steps>
              <Step status={quotationRequest.status == "WaitingForBuyer" || quotationRequest.status == "WaitingForSeller" ? "process" : "wait"} title="Lämna önskemål" icon={<MessageOutlined style={{fontSize: '150%'}} />} />
              <Step status={quotationRequest.status == "ReadyForApproval" ? "process" : "wait"} title="Acceptera" icon={<LikeOutlined style={{fontSize: '150%'}} />} />
              <Step status={quotationRequest.status == "Approved" || quotationRequest.status == "Rejected" ? "process" : "wait"} title="Klar" icon={<SmileOutlined style={{fontSize: '150%'}} />} />
            </Steps>
          </Col>
          <Col span={4}></Col>
        </Row>

        <Divider />
        <Row gutter={8} type="flex" align="middle">
          <Col xs={24} sm={24} md={24} lg={11} xl={11}>
            <VehicleCard vehicle={vehicleToReplace} />
          </Col>

          <Col xs={0} sm={0} md={0} lg={0} xl={1}>
            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
              <SwapOutlined style={{ fontSize: '400%', display: 'inline-block', verticalAlign: 'middle', color: '#808080'}} />
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <VehicleCard vehicle={{
                make: quotationRequest.make,
                model: quotationRequest.model,
                towbar: quotationRequest.towbar,
                transmission: quotationRequest.transmission,
                winterTires: quotationRequest.winterTireOption
              }} />
          </Col>
        </Row>
        <Divider />

        <Row style={{ marginBottom: 30 }}>
          <Col span={24}>
            <Descriptions title="Avtalsinformation" layout="vertical" bordered>
              <Descriptions.Item label="Avtalslängd" labelStyle={{ fontWeight: 'bold' }}>{quotationRequest.agreementLengthInMonths} månader</Descriptions.Item>
              <Descriptions.Item label="Månadskostnad" labelStyle={{ fontWeight: 'bold' }}>
                <NumberFormat value={quotationRequest.monthlyFee} displayType={'text'} thousandSeparator={true} suffix={':-'} />
              </Descriptions.Item>

              <Descriptions.Item label="Förväntad leveransdag" labelStyle={{ fontWeight: 'bold' }}>{ moment(new Date(quotationRequest.estimatedDeliveryDate)).format('YYYY-MM-DD') }</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Title level={5}>Kommentar till fordonsansvarig</Title>
          </Col>
        </Row>

        <Row style={{ marginBottom: 20 }}>
          <Col span={24}>
            <CommentComponent
              author={quotationRequest.buyerInformation.email}
              loadedComments={quotationRequest.comments}
              disableComments={quotationRequest.status == "ReadyForApproval" || quotationRequest.status == "Approved" || quotationRequest.status == "Rejected"}
              linkId={linkId}
            />
          </Col>
        </Row>

        <Divider />

        {
          quotationRequest.status == "ReadyForApproval" && (
            <Row>
              <Col span={24}>
                <Space size={10}>
                  <Input size="large" style={{ width: 160 }} onChange={onVerificationCodeChange} placeholder="Verifieringskod" prefix={<SafetyCertificateOutlined />} />

                  <Button type="primary" loading={isSubmitting} onClick={onApprove} shape="round" icon={<CheckOutlined />} size={'large'} disabled={isResponseDisabled()}>
                    Acceptera
                  </Button>

                  <Button type="secondary" loading={isDeclining} onClick={onDecline} shape="round" icon={<CloseOutlined />} size={'large'} disabled={isResponseDisabled()}>
                    Avböj
                  </Button>
                </Space>
              </Col>
            </Row>
          )
        }

      </div>
    </Content>
  )
};
