import { Layout } from 'antd';
import React from 'react';
import './App.css';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import { NavigationProvider } from './contexts/NavigationContext';
import routes from './AppRoutes';
const { Header, Content, Footer } = Layout;

function App() {
  return (
      <NavigationProvider>
        <HashRouter>
          <Layout>

            {
              /*
              <img src="https://fipprod.blob.core.windows.net/vehicleimages/vattenfall_logo.svg" width="300" style={{marginTop: 15, marginLeft: 15}} />  
              */
            }
            
            
            <Switch>
              {routes.map(({ path, component }) => (
                <Route
                  exact
                  key={path}
                  path={path}
                  component={component}
                />
              ))}
              <Redirect to={'/quotation-request'} />
            </Switch>
          </Layout>
        </HashRouter>
      </NavigationProvider>
  );
}

export default App;
