import axios from "axios";

export class VehiclePurchaseService {
    static getQuoteByLinkId = async (linkId) => {
        var vehicleServiceUri = 'https://fip-vehicleservice-webapi-prod.azurewebsites.net/api/v1/';
        const url = vehicleServiceUri + "vehicle-purchase/quotes/by-link/" + linkId;
        const response = await axios.get(url, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }});

        var result = response.data;

        if(result != null) {
            var c = result.comments.sort(function(a,b){
                return new Date(a.created) - new Date(b.created);
            });
            result.comments = c;
        }

        return result;
    }

    static getVehicleByLinkId = async (linkId) => {
        var vehicleServiceUri = 'https://fip-vehicleservice-webapi-prod.azurewebsites.net/api/v1/';
        const url = vehicleServiceUri + "vehicle-purchase/vehicles/by-link/" + linkId;
        const response = await axios.get(url, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }});

        var result = response.data;
        return result;
    }

    static comment = async (linkId, comment) => {
        var vehicleServiceUri = 'https://fip-vehicleservice-webapi-prod.azurewebsites.net/api/v1/';
        const url = vehicleServiceUri + "vehicle-purchase/quotes/by-link/" + linkId + "/buyer-comment";
        
        axios({
            method: 'POST',
            url: url,
            data: { message: comment },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    static approve = async (linkId, code) => {
        var vehicleServiceUri = 'https://fip-vehicleservice-webapi-prod.azurewebsites.net/api/v1/';
        const url = vehicleServiceUri + "vehicle-purchase/quotes/by-link/" + linkId + "/approve?code=" + code;
        
        return axios({
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    static reject = async (linkId, code) => {
        var vehicleServiceUri = 'https://fip-vehicleservice-webapi-prod.azurewebsites.net/api/v1/';
        const url = vehicleServiceUri + "vehicle-purchase/quotes/by-link/" + linkId + "/reject?code=" + code;
        
        return axios({
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }
}