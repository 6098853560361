import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Comment, Avatar, List, Tag, 
    notification, Card, Typography, Descriptions } from 'antd';
import moment from 'moment';
import { UserOutlined, PlusCircleOutlined, MinusCircleOutlined, MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { VehiclePurchaseService } from '../services/VehiclePurchaseService';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
const { TextArea } = Input;
const { Text, Link } = Typography;
const { CheckableTag } = Tag;


export default (props) => {
  const { t, i18n } = useTranslation();
  const tireTagsData = [t('Spikes'), t('NoSpikes'), t('NoWinterTires')];
  const transmissionTagsData = [t('Automatic'), t('Manual')];
  const towbarTagData = ["Ja", "Nej"];
  
  const isBigScreen = useMediaQuery({ query: '(min-width: 1225px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  useEffect(() => {

  }, []);
  
  var getExtra = () => {
      if(props.vehicle.registrationNumber) {
        return (<MinusCircleFilled style={{fontSize: '200%', color: '#b8b6b6'}} />)
      } else {
        return (<PlusCircleFilled style={{fontSize: '200%', color: '#b8b6b6'}} />)
      }
  }

  var getWinterTires = () => {
      if(isBigScreen) {
            return tireTagsData.map(tag => (
                <CheckableTag
                    key={tag}
                    checked={tag == t(props.vehicle.winterTires)}
                >
                    {tag}
                </CheckableTag>
        ));
      } else {
          return (<div>{t(props.vehicle.winterTires)}</div>);
      }
      
  }

  var getTransmission = () => {
        if(isBigScreen) {
            return transmissionTagsData.map(tag => (
                <CheckableTag
                    key={tag}
                    checked={tag == t(props.vehicle.transmission)}
                >
                    {tag}
                </CheckableTag>
            ));
        } else {
            return (<div>{t(props.vehicle.transmission)}</div>)
        }
    }

    var getTowbar = () => {
        if(isBigScreen) {
            return towbarTagData.map(tag => (
                <CheckableTag
                    key={tag}
                    checked={tag == (props.vehicle.towbar ? 'Ja' : 'Nej')}
                >
                    {tag}
                </CheckableTag>
                ));
        } else {
            return (<div>{props.vehicle.towbar ? 'Ja' : 'Nej'}</div>)
        }
    }
        

  var getDescription = () => {
    var descriptionItems = [
        (<Descriptions.Item label="Dragkrok">
            { getTowbar() }
        </Descriptions.Item>),
        (<Descriptions.Item label="Växellåda">
            { getTransmission() }
        </Descriptions.Item>)
    ];

    if(!props.vehicle.registrationNumber) {
        descriptionItems.push((<Descriptions.Item label="Vinterdäck">
            { getWinterTires() }
        </Descriptions.Item>));
    }

    if(props.vehicle.registrationNumber) {
        descriptionItems.push((<Descriptions.Item label="VIN">{props.vehicle.vehicleIdentificationNumber}</Descriptions.Item>));
    }

    return descriptionItems;
  }

  var getHeaderText = () => {
      var title = props.vehicle.make + " " + props.vehicle.model;

      if(props.vehicle.registrationNumber) {
        return title + " (" + props.vehicle.registrationNumber + ")";
      } else {
          return title;
      }
  }

  return (
    <Card
        style={{ 
            borderColor: props.vehicle.registrationNumber ? '#fafafa' : '#759E43', 
            borderRadius: '10px', 
            overflow: 'hidden', 
            color: props.vehicle.registrationNumber ? '#808080' : 'black' 
        }}
        extra={ getExtra() }
        title={ getHeaderText() }
        headStyle={{
            backgroundColor: props.vehicle.registrationNumber ? '#fafafa' : '#eaeaea',
            color: props.vehicle.registrationNumber ? '#808080' : 'black'
        }}>
            <Descriptions 
                layout="vertical" 
                title={props.vehicle.registrationNumber ? 'Gammalt fordon' : 'Nytt fordon'}
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }}
            >
                { getDescription() }
            </Descriptions>
    </Card>
  )
};
